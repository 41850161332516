import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InitialDataResolver } from './app.resolvers';
import { HomeComponent } from './components/home/home.component';
import { HomeService } from './components/home/home.service';
import { SecurityComponent } from './components/security/security.component';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { NoPermissionComponent } from './components/pages/no-permission/no-permission.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'profile' },
  {
    path: '',
    resolve: {
      initialData: InitialDataResolver
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'profile',
        loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule),
        resolve: {
          data: HomeService
        },
      },
      {
        path: 'security',
        loadChildren: () => import('./components/security/security.module').then(m => m.SecurityModule),
      },
      {
        path: 'business',
        data: {
          breadcrumb: "Business"
        },
        loadChildren: () => import('./components/business/business.module').then(m => m.BusinessModule),
      },
      {
        path: 'product',
        data: {
          breadcrumb: "Product"
        },
        loadChildren: () => import('./components/product/product.module').then(m => m.ProductModule),
      },
      {
        path: 'suggest-a-feature',
        data: {
          title: 'Suggest a feature'
        },
        loadChildren: () => import('./components/suggestions/suggestions.module').then(m => m.SuggestionsModule),
      },
      {
        path: 'report-a-problem',
        data: {
          title: 'Report a problem'
        },
        loadChildren: () => import('./components/report-a-problem/report-a-problem.module').then(m => m.ReportAProblemModule),
      },
    ]
  },
  {
    path: '',
    data: {
      layout: 'blank'
    },
    children: [
      {
        path: 'error/:type',
        component: ErrorPageComponent,
      },
      {
        path: 'no-permission/:type',
        component: NoPermissionComponent,
      },
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/profile',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
