import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { merge } from 'lodash-es';
import { SPARK_APP_CONFIG } from './config.constants';

@Injectable({
  providedIn: 'root'
})
export class SparkConfigService {
  private _config: BehaviorSubject<any>;

  /**
   * Constructor
   */
  constructor(@Inject(SPARK_APP_CONFIG) config: any) {
    // Private
    if (localStorage.getItem('config')) {
      let data = JSON.parse(localStorage.getItem('config')!);
      config.scheme = data.scheme
      config.theme = data.theme
      config.idleTime = data.idleTime
      this._config = new BehaviorSubject(config)
    }
    else {
      this._config = new BehaviorSubject(config);
    }

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for config
   */
  set config(value: any) {
    // Merge the new config over to the current config
    const config = merge({}, this._config.getValue(), value);
    let data = {
      scheme: config.scheme,
      theme: config.theme,
      idleTime: config.idleTime
    }
    localStorage.setItem('config', JSON.stringify(data));

    // Execute the observable
    this._config.next(config);
  }

  get config$(): Observable<any> {
    return this._config.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resets the config to the default
   */
  reset(): void {
    // Set the config
    this._config.next(this.config);
  }
}
