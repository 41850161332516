import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-no-permission',
  templateUrl: './no-permission.component.html',
  styleUrls: ['./no-permission.component.scss']
})
export class NoPermissionComponent implements OnInit {
  ref: any;
  errorText: any;
  errorType: number;

  constructor(
    private _activeRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this._activeRoute.params.subscribe((param) => {
      this.errorType = Number(param['type'])
    })
    this._activeRoute.queryParams.subscribe((param) => {
      this.ref = param['ref']
      this.errorText = param['error']
    })
  }

  redirect() {
    let redirectTo = new URL(environment.loginDomain)
    redirectTo.searchParams.set('reference', window.location.href)
    window.location.replace(redirectTo.href)
  }
}
