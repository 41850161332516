import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-warning-strip',
  templateUrl: './warning-strip.component.html',
  styleUrls: ['./warning-strip.component.scss']
})
export class WarningStripComponent implements OnInit {

  @Input() text;
  @Input() color;
  @Input() type = 'single';
  @Input() title = 'Note';
  constructor() { }

  ngOnInit(): void {

  }

}
