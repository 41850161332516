<div matRipple class="nav-item" [routerLink]="item.link" (click)="toggleDropdown(item)"
  [matTooltip]="isSidebarFold?item.title:null" appClickOutside (clickOutside)="isSidebarFold?closeDropDown():null"
  [matTooltipPosition]="'after'" (click)="isSidebarFold?showOverlay():null" #parent>
  <div class="example-ripple-container">
    <mat-icon class="nav-icon material-icons-outlined">{{item.icon}}</mat-icon>
    <span>{{item.title}}</span>
    <span class="dropdown-button" [class.reverse-arrow]="item.expanded">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </span>
  </div>
</div>

<ng-template #child class="child" *ngIf="isSidebarFold">
  <div class="nav-item submenu mat-app-backgroud" *ngFor="let child of item.children_menu" [routerLink]="child.link"
    [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}">
    <div class="example-ripple-container">
      <mat-icon class="nav-icon">{{child.icon}}</mat-icon>
      <span>{{child.title}}</span>
    </div>
  </div>
</ng-template>

<div *ngIf="item.children_menu && !isSidebarFold" class="child" [class.show]="item.expanded">
  <app-navigation-basic [isSubmenu]="true" *ngFor="let nav of item.children_menu;let i = index" [index]="i"
    [item]="nav">
  </app-navigation-basic>
</div>