import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, from, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, takeUntil, tap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtilityService } from './utility.service';
import { UserService } from '../user/user.service';
import { HttpService } from './http.service';

@Injectable({ providedIn: 'root' })
export class HttpIntercept implements HttpInterceptor {
  userData: any;
  personalRoleKey = this._utility.encrypt("personal")
  businessRoleKey = this._utility.encrypt("business")

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _toastr: ToastrService,
    private _utility: UtilityService,
    private _userService: UserService,
    private _httpService: HttpService,
    private _router: Router) {
    this._userService.user$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((data) => {
        this.userData = data
      })
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get current url
    const url = encodeURIComponent(window.location.href);
    return next.handle(request)
      .pipe(
        timeout(20000),
        map((event: HttpEvent<any>) => {
          if (request.headers.get('skipInterceptor'))
            return event;
          if (event instanceof HttpResponse && event.body.success == 0) {
            switch (event.body.status_code) {
              case 401: throw new HttpErrorResponse(
                {
                  status: 401
                }
              );

                break;
              case 452: // BUSINESS NO PERMISSION
              case 453: // PERSONAL NO PERMISSION
                this.redirectTo('/no-permission/' + event.body.status_code + '?error=' + event.body.message + '&ref=' + url)
                break;
              case 404: // SERVICE UNAVAILABLE
              case 500: // INTERNAL_SERVER_ERROR
                this._toastr.error(event.body.message);
                this.redirectTo('/error/' + event.body.status_code + '?error=Internal Server Error&ref=' + url)
                break;
              case 503:
                this.redirectTo('/error/' + event.body.status_code + '?error=' + event.body.message + '&ref=' + url)
                break;
              case 601: // USERNAME_NOT_VERIFIED
              case 604: // USER_NOT_VERIFIED
              case 605: // USER_BLOCKED
              case 606: // EMAIL_ALREADY_VERIFIED
              case 607: // EMAIL_NOT_VERIFIED
              case 650: // SYSTEM_DOWN
              case 609: // NO ACCESS
              case 615: // GATEWAY ERROR
                this._toastr.error(event.body.message);
                break;
              case 613: // COMING SOON
              case 614: // COME BACK
                break;
              case 200: // OK
                return event;
              case 608: // VALIDATION_ERROR
              case 603: // OTP_RESEND_LIMIT_REACHED
              case 400: // ALREADY EXIST
                this._toastr.error(event.body.message);
                return event;
              default:
                this._toastr.error('Unknown error');
                throw new HttpErrorResponse({
                  error: event.body.message,
                  status: event.body.status_code
                })
            }
          }

          return event;
        }),

        catchError((error: HttpErrorResponse) => {
          if (error.name.toString() == 'TimeoutError') {
            this._toastr.warning('Request Time out!');
          }
          else {
            switch (error.status) {
              case 404:
                this._toastr.error('Service not found');
                break;
              case 401:

                return from(this.getRefreshToken()).pipe(switchMap((res: any) => {
                  if (res.success == 1) {
                    return from(this._httpService.getWithAuth('external/common/user/token/expiresAt', res.data['api_token']))
                      .pipe(switchMap((expiry: any) => {
                        this.setRefreshToken(res.data['api_token'], res.data['refresh_token'], expiry)
                        request = request.clone({
                          setHeaders: {
                            Authorization: 'Bearer ' + res.data['api_token'],
                          },
                        });
                        return next.handle(request);
                      }))
                  }
                  else {
                    this._toastr.error('Session expired');
                    this._utility.clearSession()
                    window.location.href = environment.loginDomain
                  }
                  return throwError(error);
                }));
                break;
              case 429: // NO ACCESS
                this._toastr.error("Too many requests");
                break;
              case 500:
                this._toastr.error('Internal server error');
                this.redirectTo('/error/500?error=Internal Server Error&ref=' + url)
                break;
              default:
                this._toastr.error('Unknown error');
            }
          }

          console.log("Error while calling " + error.url)
          return throwError(error);
        })
      )
  }

  redirectTo(url) {
    const tree: UrlTree = this._router.parseUrl(window.location.pathname);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g?.segments;
    if ((s && s[0].path != 'error') || !s)
      this._router.navigateByUrl(url, { replaceUrl: true, skipLocationChange: true })
  }


  getRefreshToken() {
    let key =
      this._utility.encrypt('accessToken')
    let tokenData = JSON.parse(this._utility.decryptAES(sessionStorage.getItem(key)))
    let cookieData = JSON.parse(this._utility.decryptAES(this._utility.getCookie(tokenData.username + "_profile_token")))
    return this._httpService.post('external/auth/refreshToken', {
      'refresh_token': cookieData.refreshToken,
      'username': tokenData.username
    }, true, true)
  }

  setRefreshToken(token, refreshToken, expiry) {
    let key = this._utility.encrypt('accessToken')
    let creds = this._utility.getCookie("personalSecret")
    let accData = JSON.parse(this._utility.decryptAES(creds))
    let tokenData = JSON.parse(this._utility.decryptAES(sessionStorage.getItem(key)))
    let index = accData.findIndex((obj) => obj.username == tokenData.username);
    accData[index].expires_at = expiry.data;
    this._utility.setCookie('personalSecret', JSON.stringify(accData))
    let cookieToken: any = {};
    cookieToken.token = token;
    cookieToken.refreshToken = refreshToken;
    this._utility.setCookie(tokenData.username + "_" + "profile_token", JSON.stringify(cookieToken).toString())
  }

}


export class DataNotFoundError extends Error {
  error = "Data not found";
}

export class UnknownError extends Error {
  error = "Something went wrong!";
}
