import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ImageCropperModelComponent } from './image-cropper-modal/image-cropper-modal.component';
import { FileUploadSnackbarComponent } from './file-upload-snackbar/file-upload-snackbar.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PasswordConfirmModalComponent } from './password-confirm-modal/password-confirm-modal.component';



@NgModule({
  declarations: [
    ImageCropperModelComponent,
    FileUploadSnackbarComponent,
    PasswordConfirmModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ImageCropperModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ]
})
export class CommonComponentModule { }
