<div class="absolute inset-0">
  <!-- Main -->
  <div class="flex flex-col flex-auto items-center justify-center h-full p-6 sm:p-10" *transloco="let t">
    <img width="550" src="assets/illustration/server-down.png">
    <h3 class="mt-12 text-5xl sm:text-4xl text-center">{{errorText}}</h3>
    <ng-container *ngIf="errorType==700">
      <button mat-stroked-button color="'primary'" class="mt-6 add-round-button"
        (click)="resetData()">{{t('action.reset_site_data')}}</button>
    </ng-container>
    <ng-container *ngIf="errorType!=700">
      <button mat-stroked-button color="'primary'" class="mt-6 add-round-button"
        (click)="redirect()">{{t('action.try_again')}}</button>
    </ng-container>
  </div>
</div>