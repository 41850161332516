import { Injectable } from '@angular/core';
import { HttpService } from '../core/common-service/http.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor(
    private _httpService: HttpService,
  ) { }

  getNotifications() {
    return new Promise((resolve, reject) => {
      this._httpService.get('common/notification/getAll').subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data.data)
        }
        else
          reject(data)
      }, (error) => {
        reject(error)
      })
    })
  }

  markAllRead() {
    return new Promise((resolve, reject) => {
      this._httpService.post("common/notification/changeStatus", { notification_status: 1 }).subscribe((data: any) => {

        if (data.success) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      });
    });
  }

  markRead(key, notificationStatus) {
    notificationStatus = !notificationStatus
    return new Promise((resolve, reject) => {
      this._httpService.post("common/notification/changeStatus", { notification_key: key, notification_status: notificationStatus }).subscribe((data: any) => {

        if (data.success) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      });
    });
  }

  removeNotification(key) {
    return new Promise((resolve, reject) => {
      this._httpService.post("common/notification/changeStatus", { notification_key: key, is_deleted: 1 }).subscribe((data: any) => {

        if (data.success) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      });
    });
  }

}
