import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthUtils } from './auth.utils';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { UtilityService } from '../common-service/utility.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */
  constructor(private _authService: AuthService,
    private _utility: UtilityService,
    private _router: Router) {
  }

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request object
    let newReq = req.clone();
    if (this._authService.getAccessToken && !req.headers.get('skipAuth')) {
      let tokenData = JSON.parse(this._utility.decryptAES(this._authService.getAccessToken))
      let cookieData = JSON.parse(this._utility.decryptAES(this._utility.getCookie(tokenData.username + "_profile_token")))
      newReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + cookieData.token)
      });
    }

    // Response
    return next.handle(newReq);
  }
}
