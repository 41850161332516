<div [class.yellow-strip]="color=='yellow'" [class.red-strip]="color=='red'" [class.blue-strip]="color=='blue'"
  class="mb-3 px-3 py-3 rounded-xl flex flex-col items-start text-sm" [class.px-4]="type=='multi'">
  <ng-container *ngIf="type=='multi'">
    <div class="flex gap-1">
      <mat-icon class="icon-size-5">info</mat-icon> {{ title }}
    </div>
    <ul class="leading-relaxed list-disc ml-6 mt-1.5 gap-1">
      <li class="items-center list-item" *ngFor="let item of text;let i=index">
        <span [innerHTML]="item"></span>
      </li>
    </ul>
  </ng-container>

  <ng-container *ngIf="type=='single'">
    <div class="flex flex-col items-start gap-3">
      <div class="flex items-center gap-2">
        <mat-icon class="icon-size-5">info</mat-icon>
        <span [innerHTML]="text"></span>
      </div>
    </div>
  </ng-container>
</div>