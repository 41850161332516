<div class="absolute inset-0">
  <!-- Main -->
  <div class="flex flex-col flex-auto items-center justify-center h-full p-6 sm:p-10" *transloco="let t"> <img
      width="550" src="assets/illustration/insufficient-permission.png">
    <h3 class="mt-12 text-5xl sm:text-4xl text-center">{{errorText}}</h3>
    <ng-container>
      <p class="mt-6 text-xl secondary-text">
        {{t('auth.actions.please_login_with_your_account',{type:errorType==452?'business':'personal'})}}</p>
      <button mat-button class="mt-6 add-round-button" (click)="redirect()">
        {{t('auth.actions.back_to_login')}}</button>
    </ng-container>
  </div>
</div>