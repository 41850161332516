import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router, RouterStateSnapshot } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UtilityService } from 'src/app/core/common-service/utility.service';

@Component({
  selector: 'app-unlock-session',
  templateUrl: './unlock-session.component.html',
  styleUrls: ['./unlock-session.component.scss']
})
export class UnlockSessionComponent implements OnInit {
  unlockForm: FormGroup
  isLoading: boolean;
  username;
  redirectUrl = '/';
  config: any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(private _fb: FormBuilder,
    private _utility: UtilityService,
    private _authService: AuthService,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    private _configService: SparkConfigService,
  ) {
    this._activeRoute.params
      .subscribe(
        (params: Params) => {
          this.username = params['userName'];
        }
      );
    let params = this._activeRoute.snapshot.queryParams;
    if (params['redirectURL']) {
      this.redirectUrl = params['redirectURL'];
    }
    this._configService.config$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
        this.config = config;
      });
  }

  ngOnInit(): void {
    this.unlockForm = this._fb.group({
      'username': [{ value: this.username, disabled: true }, Validators.required],
      'password': ['', Validators.required]
    })
  }

  unlock() {
    this.isLoading = true;
    this._authService.signIn(this.unlockForm.getRawValue()).then((data) => {
      this.isLoading = false;
      this._router.navigateByUrl(this.redirectUrl)
    },
      (() => {
        this.isLoading = false;
      })
    )
  }

}
