<div matRipple class="nav-item parent" [class.submenu]="isSubmenu" *ngIf="!item.is_redirected" [routerLink]="item.link"
    [routerLinkActive]="'active'" [routerLinkActiveOptions]="isActiveMatchOptions">
    <div>
        <mat-icon *ngIf="item.icon_type=='mat'" class="nav-icon material-icons-outlined">{{item.icon}}</mat-icon>
        <fa-icon *ngIf="item.icon_type!='mat'" class="nav-icon" [icon]="[item.icon_type, item.icon]"></fa-icon>
        <span>{{item.title}}</span>
    </div>
</div>
<div matRipple class="nav-item parent" [class.submenu]="isSubmenu" *ngIf="item.is_redirected"
    (click)="redirectTo(item.redirect_url)" [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="isActiveMatchOptions">
    <div>
        <mat-icon *ngIf="item.icon_type=='mat'" class="nav-icon material-icons-outlined">{{item.icon}}</mat-icon>
        <fa-icon *ngIf="item.icon_type!='mat'" class="nav-icon" [icon]="[item.icon_type, item.icon]"></fa-icon>
        <span>{{item.title}}</span>
    </div>
</div>