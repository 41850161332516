import { Component, Inject, Renderer2, RendererFactory2, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { library, icon, findIconDefinition } from '@fortawesome/fontawesome-svg-core'
import { fas, faSackDollar } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/fontawesome-svg-core'
import { faBell as fasBell } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sparkBase';
  stopListening: Function;
  list: any = []
  constructor(
    private renderer: Renderer2,
    private _router: Router,
    private dialogRef: MatDialog,
  ) {
    if (!(window.hasOwnProperty('Tawk_API') && (Object.keys(window['Tawk_API']).length != 0))) {
      const s = this.renderer.createElement('script');
      s.text = `
      var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/`+ environment.tawkPropertyId + `/` + environment.tawkWidgetId + `';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
      })();`
      this.renderer.appendChild(document.body, s);
    }
    else
      window['Tawk_API'].showWidget()

    this.stopListening =
      renderer.listen('window', 'message', this.handleMessage.bind(this));
    if (window.addEventListener) {
      window.addEventListener("message", this.receiveMessage.bind(this), false);
    } else {
      (<any>window).attachEvent("onmessage", this.receiveMessage.bind(this));
    }

    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.dialogRef.closeAll();
    })
  }

  handleMessage(event: Event) {
    const message = event as MessageEvent;
  }
  receiveMessage: any = (event: any) => {
    if (event.origin == environment.loginDomain && event.data) {
      localStorage.setItem('accessToken', event.data.toString())
    }
  }
}
