import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from './auth.service';
import { AuthInterceptor } from './auth.interceptor';
import { NgIdleModule } from '@ng-idle/core';
import { provideAuth } from '@angular/fire/auth';
import { getAuth } from '@firebase/auth';

@NgModule({
    imports: [
        HttpClientModule,
        provideAuth(() => getAuth()),
    ],
    providers: [
        AuthService,

        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ]
})
export class AuthModule {
}
