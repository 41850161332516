import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { UnlockSessionComponent } from './unlock-session/unlock-session.component';
import { SharedModule } from '../shared/shared.module';
import { MatStepperModule } from '@angular/material/stepper';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { NoAccessModule } from './no-access/no-access.module';
import { NgIdleModule } from '@ng-idle/core';
import { CountdownModule } from 'ngx-countdown';
const routes: Routes = [


]
@NgModule({
  declarations: [
    UnlockSessionComponent,
  ],
  imports: [
    CommonModule,
    CountdownModule,
    MatStepperModule,
    ImageCropperModule,
    MatCardModule,
    MatDatepickerModule,
    MatRadioModule,
    MatMenuModule,
    OverlayModule,
    NgIdleModule,
    RouterModule.forChild(routes),
    SharedModule
  ]
})
export class AuthPagesModule { }
