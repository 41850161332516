import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorPageComponent } from './error-page/error-page.component';
import { NoPermissionComponent } from './no-permission/no-permission.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    ErrorPageComponent,
    NoPermissionComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class PagesModule { }
