import { Component, OnInit } from '@angular/core';
import { BusinessService } from '../../business/business.service';

@Component({
  selector: 'app-file-upload-snackbar',
  templateUrl: './file-upload-snackbar.component.html',
  styleUrls: ['./file-upload-snackbar.component.scss']
})
export class FileUploadSnackbarComponent implements OnInit {
  progress = 0;
  constructor(private _businessService: BusinessService) {

  }

  ngOnInit(): void {
    this._businessService.uploadProgress.subscribe((data) => {
      this.progress = data;
    })
  }

}
