import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { UtilityService } from '../../common-service/utility.service';
import { LocalStorageService } from '../../common-service/local-storage.service';
import { LocationService } from '../../location/location.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {


  personalKey = this._utility.encrypt("personal")
  businessKey = this._utility.encrypt("business")

  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _utility: UtilityService,
    private _activate: ActivatedRoute,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let url = state.url.slice(1).split('/')[0];
    const redirectUrl = state.url === '/logout' || url === 'unlock-session' ? '/' : state.url;
    let accountIndex = route.queryParams?.accIndex ? route.queryParams.accIndex : null
    return this._check(redirectUrl, route, accountIndex, state);
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  // canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //     let url = state.url.slice(1).split('/')[0];
  //     const redirectUrl = state.url === '/logout' || url === 'unlock-session' ? '/' : state.url;
  //     return this._check(redirectUrl);
  // }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this._check('/');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @param redirectURL
   * @private
   */
  private _check(redirectURL: string, route?, accountIndex?, activated?): Promise<boolean> {
    // Check the authentication status
    return this._authService.check()
      .then((authenticated) => {
        let url = new URL(environment.loginDomain)
        let redirect = new URL(window.location.origin + redirectURL)
        if (route.queryParams.login) {
          url.searchParams.set('login', route.queryParams.login)
          redirect.searchParams.delete('login')
        }
        url.searchParams.set('reference', redirect.href)
        let key = "personalSecret";
        if (this._utility.getCookie(key)) {
          let creds = this._utility.decryptAES(this._utility.getCookie(key))
          let data = JSON.parse(creds)
          // Preseve params other than accIndex
          const params = { ...route.queryParams };
          delete params.accIndex
          if (accountIndex) {
            let cookie = Object.values(data)[accountIndex] as any
            let cred = this._utility.decryptAES(authenticated)
            if (!authenticated || (authenticated && JSON.parse(cred).username != cookie?.username)) {
              if (this._utility.getCookie(cookie.profile_token)) {
                this._authService.setAccessToken(cookie?.username)
                this._router.navigate([window.location.pathname], {
                  queryParams: params
                })
                return Promise.resolve(true);
              }
              else
                return Promise.resolve(false);
              // return this._authService.signIn(cookie).then((res: any) => {
              //   if (res.success == 1) {
              //
              //     return Promise.resolve(true);
              //   }
              //   return Promise.resolve(false);
              // });

            }
          }
          else if (!authenticated) {
            if (Object.values(data).length == 1) {

              let cookie = Object.values(data)[0] as any;
              if (this._utility.getCookie(cookie.profile_token)) {
                this._authService.setAccessToken(cookie?.username)
                this._router.navigate([window.location.pathname], {
                  queryParams: params
                })
                return Promise.resolve(true);
              }
              else
                return Promise.resolve(false);
              // return this._authService.signIn(cookie).then((res: any) => {
              //   if (res.success == 1) {
              //     // this._router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              //     //   this._router.navigate([window.location.pathname]));
              //

              //     return Promise.resolve(true);
              //   }
              //   return Promise.resolve(false);
              // });

            }
            else {
              window.location.href = url.href
              return Promise.resolve(false);
            }
          }
        }
        else {
          window.location.href = url.href
          // Prevent the access
          return Promise.resolve(false);
        }
        var refreshUrl = new URL(window.location.origin + activated.url);
        if (refreshUrl.searchParams.get("accIndex")) {
          refreshUrl.searchParams.delete("accIndex");
          window.location.replace(refreshUrl.href)
        }
        // Allow the access
        return Promise.resolve(true);
      });
  }
}
