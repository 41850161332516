import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AbilityModule } from '@casl/angular';
import { NgIdleModule } from '@ng-idle/core';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CommonComponentModule } from '../components/common/common-component.module';
import { AuthModule } from './auth/auth.module';
import { TranslocoCoreModule } from './transloco/transloco.module';

@NgModule({
    imports: [
        AuthModule,
        FormsModule,
        ReactiveFormsModule,
        AbilityModule,
        CommonComponentModule,
        ToastrModule.forRoot({ toastClass: localStorage.getItem("isDarkMode") == 'true' ? 'ngx-toastr ngx-toastr-dark' : 'ngx-toastr', preventDuplicates: true }),
        //AngularFireModule.initializeApp(environment.firebaseConfig),
        //TranslocoCoreModule,
        HttpClientModule
    ],
})
export class CoreModule {
    /**
     * Constructor
     */
    constructor(
        @Optional() @SkipSelf() parentModule?: CoreModule
    ) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
