<mat-sidenav-container class="sidebar-container mat-app-background" [class.dark]="config.scheme === 'dark'"
  *transloco="let t">
  <mat-sidenav class="w-full settings-sidebar" mode="over" #rightDrawer position="end">
    <div class="flex flex-row items-center px-6 h-20 min-h-20 setting-header text-white bg-primary">
      <mat-icon class="icon-size-7 text-current">settings</mat-icon>
      <div class="ml-3 text-2xl tracking-tight">{{t('settings')}}</div>
      <button mat-icon-button="" (click)="rightDrawer.toggle();"
        class="mat-focus-indicator ml-auto mat-icon-button mat-button-base">
        <span class="mat-button-wrapper">
          <mat-icon role="img" class="mat-icon notranslate text-current mat-icon-no-color" aria-hidden="true"
            data-mat-icon-type="svg" data-mat-icon-name="x" data-mat-icon-namespace="heroicons_outline">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" fit=""
              height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </mat-icon>
        </span> </button>
    </div>
    <div class="setting-sidebar dark:bg-gray-800 h-full">
      <div class="text-xl font-medium text-secondary">{{t('scheme')}}</div>
      <div class="grid grid-cols-2 gap-3 justify-items-start mt-6">
        <!-- Dark -->
        <div class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
          [class.ring-2]="config.scheme === 'dark'" (click)=" setScheme('dark')">
          <div class="flex items-center rounded-full overflow-hidden">
            <mat-icon class="icon-size-5">dark_mode</mat-icon>
          </div>
          <div class="flex items-center ml-2 font-medium leading-5"
            [class.text-secondary]="!(config.scheme === 'dark')">
            {{t('dark')}}
          </div>
        </div>
        <!-- Light -->
        <div class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
          [class.ring-2]="config.scheme === 'light'" (click)="setScheme('light')">
          <div class="flex items-center rounded-full overflow-hidden">
            <mat-icon class="icon-size-5">light_mode</mat-icon>
          </div>
          <div class="flex items-center ml-2 font-medium leading-5" [class.text-secondary]="config.scheme === 'dark'">
            {{t('light')}}
          </div>
        </div>
      </div>

      <hr class="my-8">
      <div class="text-xl font-medium text-secondary">{{t('theme')}}</div>
      <div class="grid grid-cols-3 gap-3 justify-items-start mt-6">
        <ng-container *ngFor="let theme of themes">
          <div [matTooltip]="theme[0] | titlecase"
            class="flex w-full items-center px-4 py-3 rounded-full cursor-pointer ring-inset ring-primary bg-gray-100 dark:bg-gray-800"
            [class.ring-2]="config.theme === theme[0]" (click)="setTheme(theme[0])">
            <div>
              <div class="flex-0 w-3 h-3 rounded-full" [style.background-color]="theme[1].primary">
              </div>
            </div>
            <div class="ml-2.5 font-medium leading-5 truncate" [class.text-secondary]="config.theme !== theme[0]">
              {{theme[0] | titlecase}}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content *ngIf="layout=='default'">
    <mat-sidenav-container class="sidebar-container mat-app-background" [class.dark]="config.scheme === 'dark'">
      <mat-sidenav [class.sidebar-fold]="isSidebarFold" [class.sidebar-unfold]="!isSidebarFold" #drawer
        [opened]="!sidebarClosed" [mode]="mode">
        <app-navigation class="flex-1" [navigation]="navigation">
          <ng-container navigationHeader>
            <div class="nav-header">
              <div class="company-profile mt-2 flex items-center" [class.justify-center]="isSidebarFold">
                <img *ngIf="!isSidebarFold" [src]="config.scheme=='light'?config.logo1:config.logo1Dark" alt="">
                <img *ngIf="isSidebarFold" [src]="config.scheme=='light'?config.logo2:config.logo2Dark" alt="">
                <h4 class="ml-1" *ngIf="!isSidebarFold">
                  {{t('account')}}
                </h4>
              </div>
              <div class="dashboard-info" *ngIf="!isSidebarFold">
                <h3>{{userData?.full_name}}
                </h3>
                <p class="last-login secondary-text">
                  {{t('last_login_at_time',
                  {time: (userData?.user_login_activity?.created_at | date:'d MMM yy h:mm a') ??
                  t('few_moments_ago')})}}
                </p>
              </div>
            </div>

          </ng-container>
          <ng-container navigationFooter>
            <div fxLayout="column" class="nav-header-footer secondary-text" *ngIf="!isSidebarFold">
              <h4 fxFlex="1 0 0" class="">V {{appVersion}}</h4>
              <h4 fxFlex="1 0 0" class="mt-0">{{t('powered_by')}} <a href="https://thesparktech.com/"
                  target="_blank">{{t('spark_technologies')}}</a> </h4>
            </div>
          </ng-container>
        </app-navigation>
      </mat-sidenav>
      <mat-sidenav-content [ngStyle]="{'margin-left.px':contentMargin}">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
        <div class="router-div" style=" height: 92%;">
          <router-outlet *ngIf="true"></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </mat-sidenav-content>
  <mat-sidenav-content *ngIf="layout=='empty'">
    <mat-sidenav-container class="sidebar-container mat-app-background" [class.dark]="config.scheme === 'dark'">
      <router-outlet class="flex-1" *ngIf="true"></router-outlet>
      <div class="footer-text flex-0 text-secondary">
        <a href="{{orgURL}}" class="hover:underline" target="blank">
          {{t('reserved_spark_technologies_year',{year:currentYear})}}
        </a>
        <br>
        <ul class="copyright-links">
          <li><a href="{{orgURL}}/policy/privacy-policy" target="blank">{{t('privacy_policy')}}</a> | </li>
          <li><a href="{{orgURL}}/policy/cookie-policy" target="blank">{{t('cookie_policy')}}</a> | </li>
          <li><a href="{{orgURL}}/policy/refund-policy" target="blank">{{t('refund_policy')}}</a> | </li>
          <li><a href="{{orgURL}}/policy/disclaimer" target="blank">{{t('disclaimer')}}</a> | </li>
          <li><a href="{{orgURL}}/policy/terms-of-use" target="blank">{{t('t_of_use')}}</a> | </li>
          <li><a href="{{orgURL}}/policy/terms-and-conditions" target="blank">{{t('t_c')}}</a> | </li>
          <li><a href="{{orgURL}}/policy/terms-of-services" target="blank">{{t('t_of_services')}}</a></li>
        </ul>
      </div>
    </mat-sidenav-container>
  </mat-sidenav-content>

  <!-- Blank layout -->
  <mat-sidenav-content *ngIf="layout=='blank'">
    <mat-sidenav-container class="sidebar-container mat-app-background" [class.dark]="config.scheme === 'dark'">
      <router-outlet class="flex-1" *ngIf="true"></router-outlet>
    </mat-sidenav-container>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #toolbar>
  <mat-toolbar class="mat-app-background flex justify-between">
    <ng-container *transloco="let t">
      <div class="left">
        <button mat-icon-button (click)="foldSidebar()">
          <mat-icon aria-hidden="false" aria-label="Example home icon">menu</mat-icon>
        </button>
      </div>
      <div class="flex gap-3 mr-3">
        <button mat-button [matMenuTriggerFor]="menu">
          <div class="user-circle-avatar">
            <img src="{{ userData?.profile_photo_path ? userData?.profile_photo_path : 'assets/default.jpg'}}">
            <mat-icon *ngIf="!userData?.profile_photo_path && !userData?.first_name">person</mat-icon>
          </div>
          <h3 class="user-name ml-2 sm:block">{{userData?.first_name}}</h3>
        </button>
        <mat-menu #menu="matMenu">
          <span>
            <button mat-menu-item>
              <div class="flex items-center">
                <div class="user-circle-avatar menu-user-circle-avatar">
                  <img src="{{ userData?.profile_photo_path ? userData?.profile_photo_path : 'assets/default.jpg'}}">
                  <mat-icon *ngIf="!userData?.profile_photo_path && !userData?.first_name">person
                  </mat-icon>

                </div>
                <div class="ml-4">
                  <span class="text-base font-medium">{{userData?.first_name}}
                    {{userData?.last_name}}</span>
                  <p class="mt-0 text-sm">{{userData?.user_login?.username}}
                    <span
                      class="text-xs">({{userData?.user_login?.user_role_name?.user_role_drc_key==1?'Business':"Personal"}})</span>
                  </p>
                </div>
              </div>
            </button>
            <ng-container *ngFor="let user of accountList;let i=index">
              <ng-container *ngIf="user.username!=userData.user_login.username">
                <button mat-menu-item (click)="switchAccount(i)">
                  <div class="flex items-center w-full">
                    <div>
                      <mat-icon class="material-icons-outlined overlay-menu-icon" *ngIf="user.role==2">
                        account_box</mat-icon>
                      <mat-icon class="material-icons-outlined overlay-menu-icon" *ngIf="user.role==1">
                        assignment_ind</mat-icon>
                    </div>
                    <div class="">
                      <span>
                        {{user.full_name}}
                      </span>
                      <span class="text-xs">({{user.role==1?'Business':"Personal"}})</span>
                    </div>
                    <mat-icon class="ml-2 mr-0 icon-size-5">open_in_new</mat-icon>
                  </div>
                </button>
              </ng-container>
            </ng-container>

            <button mat-menu-item (click)="addNewAccount()" *ngIf="accountList.length<10">
              <mat-icon class="material-icons-outlined overlay-menu-icon">person_add</mat-icon>
              {{t('auth.title.sign_with_different_account')}}
              <mat-icon class="icon-size-5 ml-2">
                open_in_new</mat-icon>
            </button>
            <button mat-menu-item (click)="logout()">
              <mat-icon class="material-icons-outlined overlay-menu-icon">logout</mat-icon>
              {{t('auth.title.logout')}}
            </button>
          </span>
        </mat-menu>

        <button mat-icon-button (click)="openSettings()">
          <mat-icon [inline]="true" class="icon-size-6">settings
          </mat-icon>
        </button>
      </div>
    </ng-container>
  </mat-toolbar>
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
</ng-template>
