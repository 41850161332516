<div class="loader" *ngIf="isLoading">
    <mat-spinner [diameter]="50" style="margin: auto;"></mat-spinner>
</div>
<image-cropper style="padding: 0;" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
    [aspectRatio]="aspectRatio" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
    [roundCropper]="isRound" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>

<div mat-dialog-actions class="justify-end">
    <ng-container *transloco="let t">
        <button mat-button type="button" class="add-round-button" (click)="dialogRef.close()" [disabled]="isUploading"
            style="margin-right: 10px;">{{t('action.cancel')}}</button>
        <mat-spinner *ngIf="isUploading" [diameter]="30"></mat-spinner>
        <button mat-button class="add-round-button success-button" (click)="setImage()" *ngIf="!isUploading"
            cdkFocusInitial>
            Crop Image
        </button>
    </ng-container>
</div>