import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from 'src/app/core/common-service/http.service';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  url = environment.APIUrl;
  uploadProgress: BehaviorSubject<any>;
  businesses: any[];

  constructor(
    private _router: Router,
    private _httpService: HttpService,
    private _http: HttpClient,
    private _utility: UtilityService) {
    this.uploadProgress = new BehaviorSubject(null);
  }

  addBusiness(businessData: any) {

    // if (docs.gstFile)
    //   fileData.append('business_documents[business_gst_path]', docs.gstFile);
    // if (docs.shopActFile)
    //   fileData.append('business_documents[business_shop_act_path]', docs.shopActFile);
    // if (docs.panCardFile)
    //   fileData.append('business_documents[business_pan_card_path]', docs.panCardFile);

    return new Promise((resolve, reject) => {
      this._httpService.post('external/partner/business/addBusiness', businessData).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data);
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  addBranch(branchData: any) {
    return new Promise((resolve, reject) => {
      this._httpService.post('external/partner/business/branch/addBranch', branchData).subscribe((data: any) => {
        if (data.status_code == 200) {
          this._utility.showSuccess("Branch added successfully!")
          resolve(data);
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  uploadDocs(docs) {
    //let business = new BusinessDocument(docs);
    var fileData = new FormData();
    fileData.append('business_documents[business_gst]', docs.gstFile);
    fileData.append('business_documents[business_shop_act]', docs.shopActFile);
    fileData.append('business_documents[business_pan_card]', docs.panCardFile);
    //fileData.append('business_documents[]', JSON.stringify({ 'business_pan_card': docs.panCardFile }));

    return new Promise((resolve, reject) => {
      this._httpService.post('businessDocuments', fileData).subscribe((data: any) => {
        if (data.success) {
          //this._router.navigateByUrl('/business')
          this._utility.showSuccess("Business docs uploaded successfully!")
          resolve;
        }
        else {
          this._utility.showFailure("Failed while uploading document!")
          reject({})
        }
      }, (error) => {
        reject()
      })
    })
  }

  editBusinessContact(businessData: any) {
    return new Promise((resolve, reject) => {
      this._httpService.post('external/partner/business/updateContact', businessData).subscribe((data: any) => {
        if (data.success) {
          resolve(data);
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  editBusinessAddress(businessData: any) {
    return new Promise((resolve, reject) => {
      this._httpService.post('external/partner/business/branch/updateAddress', businessData).subscribe((data: any) => {
        if (data.success) {
          resolve(data);
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  editBranch(branchData: any) {
    return new Promise((resolve, reject) => {
      this._httpService.post('external/partner/business/branch/updateBranch', branchData).subscribe((data: any) => {
        if (data.success) {
          this._utility.showSuccess("Branch updated successfully!")
          resolve(data);
        }
        else {

          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  deleteBranch(branchData: any) {
    return new Promise((resolve, reject) => {
      this._httpService.post('external/partner/business/branch/deleteBranch', branchData).subscribe((data: any) => {
        if (data.success) {
          this._utility.showSuccess("Branch deleted successfully!")
          resolve(data);
        }
        else {

          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  resendContactOTP(data) {
    return new Promise((resolve, reject) => {
      this._httpService.post("external/partner/business/resendOTP", data).subscribe((data: any) => {
        if (data.status_code = 200) {
          resolve(data)
        }
        else {
          reject(data);
        }
      }, (error) => {
        reject(error);
      });
    });
  }

  verifyOTP(data) {
    return new Promise((resolve, reject) => {
      this._httpService.post("external/partner/business/verifyOTP", data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      });
    });
  }

  getWorkingHour() {
    let params = new HttpParams();
    params = params.set('is_list', 1);
    return new Promise((resolve, reject) => {
      this._httpService.get('external/partner/business/getHours?' + params).subscribe((data: any) => {
        resolve(data.data)
      }, (error) => {
        reject()
      })
    })
  }

  updateWorkingHour(workingHour: any) {
    return new Promise((resolve, reject) => {
      this._httpService.post('external/partner/business/branch/updateHours', workingHour).subscribe((data: any) => {
        if (data.success) {
          this._utility.showSuccess("Business hours updated successfully!")
          resolve(data);
        }
        else {

          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  updateBranchWorkingHour(workingHour: any) {
    return new Promise((resolve, reject) => {
      this._httpService.post('external/partner/business/branch/updateHours', workingHour).subscribe((data: any) => {
        if (data.success) {
          this._utility.showSuccess("Branch hours updated successfully!")
          resolve(data);
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  editBusinessDocuments(file: any, type, data) {
    var fileData = new FormData();
    fileData.append('business_documents_key', data.document_id);
    fileData.append('partner_business_key', data.business_id);
    if (type == 'gst')
      fileData.append('partner_business_documents[business_gst_path]', file);

    if (type == 'shop')
      fileData.append('partner_business_documents[business_shop_act_path]', file);

    if (type == 'pan')
      fileData.append('partner_business_documents[business_pan_card_path]', file);

    return this._http.post(this.url + 'external/partner/business/document/updateDocument', fileData, {
      reportProgress: true,
      observe: 'events'
    })

  }

  getBusinessList(page, productId, keyword?, limit?) {
    let params = new HttpParams();
    params = params.set('page', page);
    params = params.set('product_drc_key', productId);
    if (limit)
      params = params.set('limit', limit);
    if (keyword)
      params = params.set('search_key', keyword);
    return new Promise((resolve, reject) => {
      this._httpService.get('external/partner/business/getAll?' + params.toString())
        .subscribe((data: any) => {
          resolve(data.data)
        }, (error) => {
          reject(error)
        })
    })
  }

  getBusinessByProduct(slug) {
    return new Promise((resolve, reject) => {
      this._httpService.get('external/partner/business/getAll?service=' + slug).subscribe((data: any) => {
        if (data.success == 1)
          resolve(data.data)
        else
          reject(data)
      }, (error) => {
        reject()
      })
    })
  }

  getBranchById(branchId) {
    return new Promise((resolve, reject) => {
      this._httpService.get('external/partner/business/branch/getByID?business_branch_key=' + branchId).subscribe((data: any) => {
        resolve(data.data)
      }, (error) => {
        reject()
      })
    })
  }

  getBusinessTypes(productSlug) {
    let params = new HttpParams();
    params = params.set('is_list', 1);
    params = params.set('service', productSlug);
    return new Promise((resolve, reject) => {
      this._httpService.get('public/attribute/product/business/type/getAll?' + params.toString()).subscribe((data: any) => {
        resolve(data.data)
      }, (error) => {
        reject()
      })
    })
  }

  getBusinessDocuments(productSlug, businessType) {
    let params = new HttpParams();
    params = params.set('service', productSlug);
    params = params.set('business_type_drc_key', businessType);
    return new Promise((resolve, reject) => {
      this._httpService.get('external/partner/product/document/getAll?' + params.toString()).subscribe((data: any) => {
        resolve(data.data)
      }, (error) => {
        reject()
      })
    })
  }

  getBusinessBranches(page, businessId, limit?) {
    let params = new HttpParams();
    params = params.set('page', page);
    params = params.set('partner_business_key', businessId);
    if (limit)
      params = params.set('limit', limit);
    return new Promise((resolve, reject) => {
      this._httpService.get('external/partner/business/branch/getAll?' + params.toString())
        .subscribe((data: any) => {
          resolve(data.data)
        }, (error) => {
          reject(error)
        })
    })
  }

  editBusinessLogo(data) {
    data.business_logo = data.business_logo.replace("data:image/png;base64,", "")
    return new Promise((resolve, reject) => {
      this._httpService.post('external/partner/business/updateMedia', data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  editBusinessCover(data) {
    if (data.business_cover_image)
      data.business_cover_image = data.business_cover_image.replace("data:image/png;base64,", "")
    return new Promise((resolve, reject) => {
      this._httpService.post('external/partner/business/updateMedia', data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  editBusinessDoc(data, isExist) {
    return new Promise((resolve, reject) => {
      this._httpService.post('external/partner/business/document/' + (isExist ? 'updateDocument' : 'addDocument'), data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }
}
